exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-makijaz-js": () => import("./../../../src/pages/makijaz.js" /* webpackChunkName: "component---src-pages-makijaz-js" */),
  "component---src-pages-o-mnie-js": () => import("./../../../src/pages/o-mnie.js" /* webpackChunkName: "component---src-pages-o-mnie-js" */),
  "component---src-pages-realizacje-js": () => import("./../../../src/pages/realizacje.js" /* webpackChunkName: "component---src-pages-realizacje-js" */),
  "component---src-pages-stylizacja-js": () => import("./../../../src/pages/stylizacja.js" /* webpackChunkName: "component---src-pages-stylizacja-js" */),
  "component---src-pages-warsztaty-js": () => import("./../../../src/pages/warsztaty.js" /* webpackChunkName: "component---src-pages-warsztaty-js" */)
}

